/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useState, useEffect } from 'react';
import {
    List,
    Datagrid,
    TextField,
    ShowButton,
    DateField,
    BooleanField,
    Show,
    SimpleShowLayout,
    useDataProvider,
    TopToolbar,
    ExportButton,
} from 'react-admin';

const Title = ({ record }) => {
    return <span>Веб-форма {record ? `"${record.email}"` : ''}</span>;
};

// const Filters = [
//     <TextInput source="q" label="Search" alwaysOn />,
//     /*<ReferenceInput source="userId" label="User" reference="users" allowEmpty>
//         <SelectInput optionText="name" />
//     </ReferenceInput>,*/
// ];

const WebFormListActions = () => (
    <TopToolbar>
        <ExportButton maxResults={1000000} />
    </TopToolbar>
);

export const WebFormList = props => (
    <List bulkActionButtons={false} {...props} actions={<WebFormListActions />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="type.title" label="Тип заявки" />
            <TextField source="name" label="Имя" />
            <TextField source="surname" label="Фамилия" />
            <TextField source="phone" label="Телефон" />
            <TextField source="email" label="Email" />
            <TextField source="club_card" label="Клубная карта" />
            <ShowButton label="" />
        </Datagrid>
    </List>
);

export const WebFormShow = (props) => {
    const [data, setData] = useState({});

    const dataProvider = useDataProvider();

    useEffect(() => {
        if (props?.id) {
            dataProvider.getOne('web-forms', { id: Number(props?.id) })
                .then(({ data }) => {
                    setData(data);
                })
            // .catch((err) => console.log(err.message));
        }
    }, [props?.id]);

    // 1 — заявка на консультацию дизайнера
    const isConsultationApplication = data?.type_id === 1;
    // 2 — заявка на аренду
    const isRentApplication = data?.type_id === 2;
    // 3 - заявка на участие
    const isParticipationApplication = data?.type_id === 3;
    // 4 — обратная связь
    const isFeedback = data?.type_id === 4;
    // 5 — регистрация на мероприятие
    const isRegistration = data?.type_id === 5;
    // 6 - заявка на аренду конференц зала
    const isConferenceRoomApplication = data?.type_id === 6;

    return (
        <Show {...props} title={<Title />}>
            <SimpleShowLayout>
                <TextField source="type.title" label="Тип заявки" />
                {isRegistration && <TextField source="material" label="Название мероприятия" />}

                {!isRentApplication && !isFeedback && <TextField source="name" label="Имя" />}

                {(isParticipationApplication || isRegistration || isConferenceRoomApplication) && <TextField source="surname" label="Фамилия" />}
                {isParticipationApplication && <TextField source="patronymic" label="Отчество" />}
                <TextField source="phone" label="Телефон" />
                <TextField source="email" label="Email" />
                {isParticipationApplication && <TextField source="work_experience" label="Стаж работы" />}
                {(isRentApplication || isParticipationApplication) && <TextField source="site" label="Ссылка на портфолио/сайт" />}

                {(isConsultationApplication || isConferenceRoomApplication) && <DateField source="date_time" label="Дата и время" showTime />}

                {isRentApplication && <TextField source="company_name" label="Название компании" />}
                {isConsultationApplication && <TextField source="consultation_type" label="Тип консультации" />}
                {isRentApplication && <TextField source="company_activity" label="Вид деятельности компании" />}
                {(isRentApplication || isFeedback) && <TextField source="contact" label="Контактное лицо" />}
                {isRentApplication && <TextField source="square" label="Площадь" />}
                {isRentApplication && <TextField source="room_type" label="Тип помещения" />}
                {/* <TextField source="found_out_about_us" label="Откуда вы узнали о нас" /> */}
                {!isRegistration && <TextField source="additional_info" label="Дополнительная информация" />}
                {isRegistration && <BooleanField source="is_design_community" label="Я представитель дизайн сообщества" />}
                {isRegistration && <BooleanField source="agree_subscription" label="Я хочу получать анонсы по электронной почте" />}

                <DateField source="create_at" label="Дата и время создания" showTime />
            </SimpleShowLayout>
        </Show>
    )
};